export default {
  fomo:{
    startnode:"초기 노드",
    node1:"1. 동일한 양의 LP 헤드마인을 즐겨보세요",
    node2:"2. 전체 마이닝 풀 네트워크에서 5% 가중 배당",
    node3:"3. 스튜디오 지원 정책을 우선적으로 확보합니다.",
    payCoin:"결제 통화",
    payNum:"결제 수량",
    balance:"균형",
    requstnode:"초기 노드 가입 신청",
    joinend:"합류했다",
    joinsuccess:"노드에 성공적으로 참여했습니다.",
    joinerror:"참여하지 못했습니다.",
  },
  news:{
    message:'공고',
    nowtotal:'현재 총량',
    endtotal:'잉여 수량',
    nowPrice:"현재 단가",
    nextPrice:"다음 분할 가격",
    queue:"대기열 수",
    nextqueue:"시퀀스 크기 나누기",
    angin:"복에",
    amount:"개인 투자액",
    level:"등급",
    nowlevel:"현재 단계",
    levelamount:"투자액",
    star:"별",
    requst:"신청",
    requstIn:"심사 중",
    receive:"수령",
    received:"received",
    requstFail:"찾을 수 없음",
    issued:"발급을 기다리다",
    time:"시간",
    requstAddress:"신청 주소",
    requstID:"신청 id",
    requstLEVLE:"신청 등급",
    needVoucher:"소모적분",
    needUSDT:"소모적분",
    tip:"세일즈 알람",
    tipMessage:"축하합니다!당신의 재테크 주문서는 이미 판매를 지시했습니다",
    inokw:"알겠어요",
    start:"초",
    minerid1:"앰배서더",
    minerid2:"대사 소형 광기",
    minerid3:"특사광기",
    minerid4:"특사 소형 광기",
    nclaimed:"수령을 기다리다",
    address:"주소",
    number:"수량",
    miner:"광산 기계",
    order:"주문서",
    period:"소속 기수",
    type:"유형",
    shares:"주식 할당",
    split:"분리",
    vip:"회원",
    allLevelamount:"총투자액",
    totalPerformance:"총 실적",
    area:"구",
    foundation:"기금회",
    pushon:"게시하기",

  },

  com: {
    empty: 'कोई डेटा नहीं',
    pre: 'पिछला पृष्ठ',
    next: 'अगला पृष्ठ',
    balance: 'संतुलन',
    approve: 'अधिकृत करें {0}',
    link:'लिंक नहीं',
    reward: 'प्राप्त करना',
    welcome: 'कल्याण में आपका स्वागत है।डीएओ!',
    address: 'आमंत्रितकर्ता का पता',
    enter: 'प्रवेश करना'
  },
  msg: {
    balanceInsufficient: 'खरीद के लिए उपलब्ध वर्तमान यूएसडीटी {0}है',
    purchaseSucc:'खरीदा सफल',
    PurchaseFailed: 'खरीदारी विफल',
    approveSucc: 'प्राधिकरण सफल',
    approveFaile:'प्राधिकरण विफल',
    transferSucc: 'ट्रांसफर सफल',
    transferFailed:'स्थानांतरण विफल',
    copySucc: 'क्लिपबोर्ड पर चिपकाएं',
    invit: 'आमंत्रण पता बाध्य है',
    bind: 'कृपया बेहतर पता दर्ज करें',
    invalidAddress: 'अमान्य पता, कृपया जांचें और पुनः प्रयास करें',
  },
  web: {
    banner: {
      h1: 'कल्याण.डीएओ',
      text:'मेटावर्स का पहला पारस्परिक सहायता पारिस्थितिकी तंत्र',
      launch: 'प्रक्षेपण',
      ljCG:'लिंक सफलतापूर्वक',
      ljQB:'लिंक वॉलेट',
      bdYQ:'बाइंड आमंत्रण'
    },
    team: {
      title: 'टीम',
      subTitle: 'आमंत्रण लिंक:',
      power: 'टीम का प्रदर्शन',
      subTitle2: 'मेरी टीम:',
      address: 'पता',
      sub: 'छात्र के नीचे कुल प्रदर्शन'
    },
    market: {
      title:'एनएफटी मार्केट',
      text1: 'डिजिटल स्वामित्व',
      text2: 'सुरक्षा',
      text3: 'व्यापार करने की स्वतंत्रता'
    },
    pool: {
      title: 'एलपी पूल',
      text1: 'संपूर्ण DEFI तरल खनन पारिस्थितिकी में भाग लें',
      text2: 'स्थिर उच्च ROI का आनंद लें'
    },
    help: {
      title: 'प्यार और पारस्परिक सहायता',
      text1: 'मूल्य वर्धित विशेषता का असीमित मूल्य है',
      text2: 'प्लेटफ़ॉर्म लाभांश का आनंद लें'
    },
    crowd: {
      title: 'लव क्राउडफंडिंग',
      subTitle: 'लिमिटेड इश्यू लव एनएफटी कार्ड',
      name1: 'लव एंबेसडर',
      name2: 'प्रेम दूत'
    },
    swap: {
      title:'स्वैप',
      subTitle1: 'विकेंद्रीकृत विनिमय',
      subTitle2: 'मुफ़्त विनिमय',
      info: [{
        title: 'वॉलेट',
        text: 'कल्याण डीएओ डिजिटल वॉलेट'
      }, {
        title: 'गेमफी',
        text: 'गेमफी = गेम + डेफी'
      }]
    },
    footer: {
      name: 'कल्याण। डीएओ',
      welcome: 'कल्याण के साथ बने रहने के लिए हमारे ऑनलाइन चैनल में शामिल होने के लिए आपका स्वागत है।दाओ समुदाय।'
    }
  },
  _bind_:{
    _no_id_: 'ID existenceしません',
    _transfer_: 'translatio',
    _sell_:'売る',
    _withdraw_: 'discede',

    _djID_: '포인트 ID를 입력하세요',
    _myID_: 'आपका नाम',
    _jdXJ_: 'मुझे पता है',
    _ztID_: '직접 푸시 ID를 입력하세요',
    _must_:'필수*',
    _nomust_: 'हाय',
    _bind_: 'बाइंड',
    _push_:'申请星级奖励',
    _bind_successful_: 'सफलतापूर्वक',
    _bind_failed_: '결합 실패',
    _no_stock: 'हाय-दुःख की बात है।',
    _withdraw_success_: 'सफलता का समय',
    _withdraw_failed_: 'वापसी',
    _sell_success_: 'बेचने का मौका',
    _sell_failed_: 'हानिकारक',
  },
  app: {
    invest:{
     invest: `निवेश`,
     MyInvest:`मेरा निवेश`,
     market: `बाजार`,
     myReward: `माई रिवार्ड्स`,
     investInfo: `होल्डिंग स्टॉक स्थायी आय प्राप्त कर सकते हैं, आइए निवेश करने के लिए उचित राशि चुनें ~`,
     voucher: `वाउचर`,
     EstimatedStock: `अनुमानित स्टॉक उपलब्ध`,
     FixedStock: `फिक्स्ड स्टॉक`,
     Stock: `स्टॉक`,
     NonFixedStock:`NonFixedStocks`,
     Withdraw: `निकालें`,
     AvailableStock: `स्टॉक बिक्री के लिए उपलब्ध`,
     transfer: `स्थानांतरण`,
     sell: `बिक्री के लिए`,
     state0: `ऑडिट निष्कर्षण>`,
     state1: `समीक्षा अधीन...`,
     state2: `ऑडिट विफल!`,
     balance: `बैलेंस`,
     unitprice: `यूनिट मूल्य`,
     price: `कीमत`,
     Buy: `खरीदें`,
     withdrawAll: `वापस लेना`,
     ReferralRewards: `रेफ़रल इनाम`,
     ClickReward: `क्लिक रिवॉर्ड्स`,
     InteractionRewards: `इंटरैक्शन इनाम`,
     MatchReward: `मैच रिवार्ड्स`,
     ManagementRewards: `प्रबंधन पुरस्कार`,
     OneClickCollection: `एक-क्लिक संग्रह`,
     DirectSubordine: `मेरे प्रत्यक्ष अधीनस्थ`,
     Address: `पता`,
     CommunityPerformance: `सामुदायिक प्रदर्शन`,
     sellInfo: `निश्चित शेयरों को वापस लेने से टर्मिनल विभाजित हो जाएगा, आय कमाना बंद हो जाएगा, क्या आप निश्चित रूप से समीक्षा के लिए जमा करना चाहते हैं और निश्चित शेयरों को वापस लेना चाहते हैं? `,
    },
    confirm: `पुष्टि करें`,
    cancel: `रद्द करें`,
    amount0: `उपलब्ध राशि 0 है! `,
    ReceiveSuccess: `एक क्लिक के साथ सफल रसीद!`,
    ReceiveFail:`एक कुंजी से प्राप्त करने में विफल!`,
    PleaseEnterNumber:`कृपया नंबर दर्ज करें`,
    PleaseEnterRight:`कृपया सही मान दर्ज करें`,
    InsuffifityStock: `अपर्याप्त स्टॉक बिक्री के लिए उपलब्ध है`,
    PleaseEnterRightAddress:`कृपया सही पता दर्ज करें`,
    PleaseLinkWallet:`कृपया वॉलेट को पहले लिंक करें! `,
    PleaseEnterAddress:`कृपया ट्रांसफर एड्रेस दर्ज करें`,
    PleaseSelectInvest:`कृपया एक निवेश आइटम चुनें`,
    TransferSuccessful: `ट्रांसफर सफल`,
    TransferFailed: `स्थानांतरण विफल`,
    ApproveSuccessful:`प्राधिकरण सफल! `,
    ApproveFailed:`प्राधिकरण विफल! `,
    investmentSuccessful: `निवेश सफल`,
    WithdrawSuccessful: `सफलतापूर्वक वापस ले लें`,
    WithdrawFailed: `निकासी विफल`,
    investmentFailed: `निवेश विफल`,
    investmentFailed2:'复投失败',
    InfectionsUSDTbalance: `USDT शेष अपर्याप्त है`,
    noApproveInfo:`यह पता चला है कि आप अधिकृत नहीं हैं, और आप अधिकृत कर रहे हैं! `,
    coin: {
      title: 'सिक्के',
      buy:'खरीदें',
      purchased: 'खरीदा:'
    },
    nft: {
      title: 'सिक्के',
      subscription: 'सदस्यता',
      has: 'स्वामित्व है',
      not: 'स्वामित्व है',
      cast: 'कास्ट',
      signle: '{0}USDT की एकल सदस्यता प्राप्त कर सकते हैं'
    },
    mine: {
      title: 'सदस्यता',
      subTitle: 'माई एनएफटी',
      transfer: 'स्थानांतरण',
      team: 'टीम',
      subTitle1: 'आमंत्रण लिंक:',
      subTitle2: 'टीम प्रदर्शन',
      subTitle3: 'मेरी टीम:',
      level: 'श्रेणी',
    },
    cooperation:{
      love:'प्यार और आपसी सहायता',
      income:'कल की कमाई',
      total:'कुल मुनाफा',
      receive:'प्राप्त करना',
      invest:'निवेश करना',
      Authorize:'मंजूर',
      history:'इतिहास रिकॉर्ड',
      personal:'व्यक्तिगत निवेश कोटा',
      all:'सभी निवेश कोटा',
      promote:'विपणन',
      Link:'प्रयोजित संपर्क',
      copy:'प्रतिलिपि',
      award:'पदोन्नति इनाम',
      tzjl:'निवेश प्रोत्साहन',
      grade:'श्रेणी',
      performance:'प्रचार प्रदर्शन',
      mine:'मेरा प्रचार',
      address:'पता',
      yj:'प्रदर्शन',
      pre: 'पिछला पृष्ठ',
      next: 'अगला पृष्ठ',
      investmentRecord:'निवेश रिकॉर्ड',
      amount:'रकम',
      quantity:'उपलब्ध मात्रा',
      copyCG:'सफलतापूर्वक कॉपी करें',
      No:'वर्तमान में कोई आय उपलब्ध नहीं है',
      receiveCG:'सफलतापूर्वक प्राप्त हुआ',
      receiveSB:'दावा करने में विफल',
      approveCG:'सफलतापूर्वक स्वीकृत',
      approveSB:'स्वीकृति विफल',
      investCG:'निवेश की सफलता',
      investSB:'निवेश की सीमा पूरी हो गई',
      Balance:'संतुलन',
      withdraw:'वापस लेना',
      pledge:'प्रतिज्ञा करना',
      pledgeCG:'प्रतिज्ञा सफलता',
      pledgeSB:'प्रतिज्ञा विफल',
      withdrawCG:'निकासी सफल रही',
      withdrawSB:'वापस लेने में विफल',
      primary:'मुख्य',
      intermediate:'मध्यवर्ती',
      advanced:'विकसित',
      none:'कोई भी नहीं',
      Finish:'अनुबंध समाप्त हो गया है',
      record:'कमाई का रिकॉर्ड',
      Amount:'राशि',
      date:'समय',
      sy:'आय',
      rq:'तारीख़ चुनें',
      year:'साल',
      moon:'चांद',
      day:'दिन'
    }
  }
}
