export default {
  fomo:{
    startnode:"初始节点",
    node1:"1. 享受等金额LP头矿",
    node2:"2. 矿池全网5% 加权分红",
    node3:"3. 优先获取工作室扶持政策",
    payCoin:"支付币种",
    payNum:"支付数量",
    balance:"余额",
    requstnode:"申请加入初始节点",
    joinend:"已加入",
    joinsuccess:"加入节点成功",
    joinerror:"加入失败",
  },
  news:{
    message:'公告',
    nowtotal:'当前总量',
    endtotal:'剩余量',
    nowPrice:"当前单价",
    nextPrice:"下次拆分价格",
    queue:"排队量",
    nextqueue:"拆分排队量",
    angin:"复投",
    amount:"个人投资额",
    level:"等级",
    nowlevel:"当前等级",
    levelamount:"投资额",
    star:"星",
    requst:"申请",
    requstIn:"审核中",
    receive:"领取",
    received:"已领取",
    requstFail:"领取失败",
    issued:"待发放",
    time:"时间",
    requstAddress:"申请地址",
    requstID:"申请ID",
    requstLEVLE:"申请等级",
    needVoucher:"预消耗积分",
    needUSDT:"预消耗积分",
    tip:"出售提醒",
    tipMessage:"恭喜您！您的理财订单已指导出售",
    inokw:"知道了",
    start:"初",
    minerid1:"大使矿机",
    minerid2:"大使小矿机",
    minerid3:"特使矿机",
    minerid4:"特使小矿机",
    nclaimed:"待领取",
    address:"地址",
    number:"数量",
    miner:"矿机",
    order:"订单",
    period:"所属期数",
    type:"类型",
    shares:"配股",
    split:"拆分",
    vip:"会员",
    allLevelamount:"总投资额",
    totalPerformance:"总业绩",
    area:"区",
    foundation:"基金会",
    pushon:"发布于",

  },
  com: {
    empty: 'No Data',
    pre: '上一页',
    next: '下一页',
    balance: 'Balance',
    approve: '授权{0}',
    link: '未链接',
    reward: '领取',
    welcome: '欢迎进入Welfare.DAO！',
    address: '邀请人地址',
    enter: '进入'
  },
  msg: {
    balanceInsufficient: '当前USDT剩余可购买量为{0}',
    purchaseSucc: '购买成功',
    PurchaseFailed: '购买失败',
    approveSucc: '授权成功',
    approveFaile: '授权失败',
    transferSucc: '转让成功',
    transferFailed: '转让失败',
    copySucc: '粘贴到剪切板',
    invit: '邀请地址无效',
    bind: '请输入上级地址',
    invalidAddress: '无效地址，请检查后重新尝试',
  },
  web: {
    banner: {
      h1: 'Welfare.DAO',
      text: 'Web3.0聚合金融服务平台',
      launch: '启动',
      ljCG:'链接成功',
      ljQB:'链接钱包',
      bdYQ:'绑定邀请'
    },
    team: {
      title: '团队',
      subTitle: '邀请链接：',
      power: '团队业绩',
      subTitle2: '我的团队：',
      address: '地址',
      sub: '伞下总业绩',
      reward: '领取',
    },
    market: {
      title: 'NFT市场',
      text1: '数字所有权',
      text2: '安全性',
      text3: '交易自由'
    },
    pool: {
      title: 'LP池',
      text1: '参与整个 DEFI 流动挖矿生态',
      text2: '享受静态高额投资回报收益'
    },
    help: {
      title: '爱心互助',
      text1: '增值属性价值无限',
      text2: '享受平台分红'
    },
    crowd: {
      title: '爱心众筹',
      subTitle: '限量发行爱心NFT卡',
      name1: '爱心大使',
      name2: '爱心特使'
    },
    swap: {
      title: 'SWAP',
      subTitle1: '去中心化交易所',
      subTitle2: '自由兑换',
      info: [{
        title: '钱包',
        text: 'Welfare DAO数字钱包'
      }, {
        title: 'GAMEFI',
        text: 'Gamefi = Game + Defi'
      }]
    },
    footer: {
      name: 'Welfare.DAO',
      welcome: '欢迎你加入我们的在线渠道， 与Welfare.Dao社区保持同步。'
    }
  },
  _bind_:{
    _no_id_:'双线见点ID已满',
    _transfer_:'转让',
    _sell_:'出售',
    _withdraw_:'提取',

    _djID_:'请输入见点ID',
    _myID_:'我的见点ID',
    _jdXJ_:'见点下级',
    _ztID_:'请输入直推ID',
    _must_:'必填*',
    _nomust_:'非必填',
    _bind_:'绑定',
    _push_:'申请星级奖励',
    _bind_successful_:'绑定成功',
    _bind_failed_:'绑定失败',
    _no_stock:'当前没有可用wel',
    _withdraw_success_:'提取成功',
    _withdraw_failed_:'提取失败',
    _sell_success_:'出售成功',
    _sell_failed_:'出售失败',
  },
  app: {
    invest:{
      invest:'爱心理财',
      MyInvest:'我的投资',
      market:'市场',
      myReward:'我的奖励',
      investInfo:'持有wel可获得持续性收益，来选择合适金额投资吧~',
      voucher:'开户积分',
      EstimatedStock:'预计可得到wel',
      FixedStock:'固定wel量',
      Stock:'股票',
      NonFixedStock:'非固定wel量',
      Withdraw:'提取',
      AvailableStock:'可出售wel量',
      transfer:'转让',
      sell:'出售',
      state0:'审核提取',
      state1:'审核中...',
      state2:'审核失败!',
      balance:'余额',
      unitprice:'单价',
      price:'价格',
      Buy:'购买',
      withdrawAll:'撤回',
      ReferralRewards:'培育奖励',
      ClickReward:'幸运奖励',
      InteractionRewards:'互动奖励',
      MatchReward:'平衡奖励',
      ManagementRewards:'管理奖励',
      OneClickCollection:'一键领取',
      DirectSubordinate:'我的直推下级',
      Address:'地址',
      CommunityPerformance:'社区业绩',
      sellInfo:'提取固定wel量将终端拆分，停止获取收益，确定提交审核并提取固定wel量吗？',
    },
    confirm:'确认',
    cancel:'取消',
    amount0:'可领取量为0！',
    ReceiveSuccess:'一键领取成功!',
    ReceiveFail:'一键领取失败!',
    PleaseEnterNumber:'请输入数量',
    PleaseEnterRight:'请输入正确的值',
    InsufficientStock:'可出售wel量不足',
    PleaseEnterRightAddress:'请输入正确的地址',
    PleaseLinkWallet:'请先链接钱包！',
    PleaseEnterAddress:'请输入转让地址',
    PleaseSelectInvest:'请选择投资项',
    TransferSuccessful:'转让成功',
    TransferFailed:'转让失败',
    ApproveSuccessful:'授权成功！',
    ApproveFailed:'授权失败！',
    investmentSuccessful:'投资成功',
    WithdrawSuccessful:'撤回成功',
    WithdrawFailed:'撤回失败',
    investmentFailed:'投资失败',
    investmentFailed2:'复投失败',
    InsufficientUSDTbalance:'USDT余额不足',
    noApproveInfo:'检测到您没有授权，正在授权！',
    coin: {
      title: '铸币',
      buy: '购买',
      purchased: '已购买：'
    },
    nft: {
      title: '铸币',
      subscription: '认购',
      has: '已拥有',
      not: '未拥有',
      cast: '已铸造',
      signle: '单次认购{0}USDT可获得',
    },
    mine: {
      title: '我的',
      subTitle: '我的NFT',
      transfer: '转让',
      team: '团队',
      subTitle1: '邀请链接：',
      subTitle2: '团队业绩',
      subTitle3: '我的团队:',
      level: '等级',
    },
    cooperation:{
      love:'爱心互助',
      income:'昨日收益',
      total:'总收益',
      receive:'领取',
      invest:'投资',
      Authorize:'授权',
      history:'历史记录',
      personal:'个人投资额度',
      all:'全部投资额度',
      promote:'市场推广',
      Link:'推广链接',
      copy:'复制',
      award:'推广奖励',
      tzjl:'投资奖励',
      grade:'等级',
      performance:'推广业绩',
      mine:'我的推广',
      address:'地址',
      yj:'业绩',
      pre: '上一页',
      next: '下一页',
      investmentRecord:'投资记录',
      amount:'金额',
      quantity:'可领取数量',
      copyCG:'复制成功',
      No:'暂无收益可领取',
      receiveCG:'领取成功',
      receiveSB:'领取失败',
      approveCG:'授权成功',
      approveSB:'授权失败',
      investCG:'投资成功',
      investSB:'已达投资限额',
      Balance:'余额',
      withdraw:'撤回',
      pledge:'质押',
      pledgeCG:'质押成功',
      pledgeSB:'质押失败',
      withdrawCG:'撤回成功',
      withdrawSB:'撤回失败',
      primary:'初级',
      intermediate:'中级',
      advanced:'高级',
      none:'无',
      Finish:'合约已结束',
      record:'收益记录',
      Amount:'额度',
      date:'时间',
      sy:'收益',
      rq:'选择日期',
      year:'年',
      moon:'月',
      day:'日'
    }
  }
}
