<template>
  <!-- 未知官网有几个路由的情况下将页头封装成组件 -->
  <header>
    <div class="nav container" ref="header">
      <img src="../assets/img/web-logo.png">
      <div class="info">
        <span v-if="!address"> <i class="err"></i> {{ $t('com.link') }}</span>
        <span v-else-if="!isBNB"> <i class="err"></i> {{ address | addressCharacter(6, 6) }}</span>
        <span v-else> <i></i> {{ address | addressCharacter(6, 6) }}</span>
        <div class="language" @click="show = !show">
          {{ language }}
          <div :class="{'language-box': true, show: show}">
            <ul class="language-list">
              <li @click="handleExchangeLanguage(0)">CN</li>
              <li @click="handleExchangeLanguage(1)">EN</li>
              <li @click="handleExchangeLanguage(2)">JP</li>
              <li @click="handleExchangeLanguage(3)">KN</li>
              <li @click="handleExchangeLanguage(4)">IDN</li>
            </ul>
          </div>
        </div>
<!--        <img class="meun" src="../assets/img/meun.png" @click="drawer = true">-->
      </div>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="drawer"
      :direction="'rtl'"
      :size="'100%'">
      <ul class="meun-list">
        <li @click="handleClick(0)">{{ $t('web.team.title') }}</li>
        <li @click="handleClick(1)">{{ $t('web.market.title') }}</li>
        <li @click="handleClick(2)">{{ $t('web.pool.title') }}</li>
        <li @click="handleClick(3)">{{ $t('web.help.title') }}</li>
        <li @click="handleClick(4)">{{ $t('web.crowd.title') }}</li>
        <li @click="handleClick(5)">{{ $t('web.swap.title') }}</li>
        <li @click="handleClick(6)">{{ $t('web.swap.info[0].title') }}</li>
        <li @click="handleClick(7)">{{ $t('web.swap.info[1].title') }}</li>
      </ul>
      <div class="drawer-close" @click="drawer = false"></div>
    </el-drawer>
  </header>
</template>

<script>
export default {
  name: 'Website-Header',
  data () {
    return {
      drawer: false,
      list: ['team', 'NFT', 'pool', 'help', 'crowd', 'swap', 'wallet', 'GameFi'],
      show: false,
      language: localStorage.getItem('locale') || 'CN'
    }
  },
  props: ['address'],
  computed:{
    isBNB(){
      return Number(window.ethereum.chainId) == 56
    }
  },
  methods: {
    handleClick (index) {
      // console.log(this.list[index]);
      let top = this.$refs.header.offsetHeight
      this.drawer = false
      this.$emit('id', this.list[index], top)
    },
    handleExchangeLanguage (index) {
      // index -> 0,中文  1. 英文 2.日语  3.韩语 4.印度语
      // console.log(index);
      switch (index) {
        case 0:
          localStorage.setItem('locale', 'CN')
          this.$i18n.locale = 'CN'
          this.language = 'CN'
          break;
        case 1:
          localStorage.setItem('locale', 'EN')
          this.$i18n.locale = 'EN'
          this.language = 'EN'
          break;
        case 2:
          localStorage.setItem('locale', 'JP')
          this.$i18n.locale = 'JP'
          this.language = 'JP'
          break;
        case 3:
          localStorage.setItem('locale', 'KN')
          this.$i18n.locale = 'KN'
          this.language = 'KN'
          break;
        case 4:
          localStorage.setItem('locale', 'IDN')
          this.$i18n.locale = 'IDN'
          this.language = 'IDN'
          break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  // color: #FFF;
  font-size: 0.28rem;
  ::v-deep .el-drawer {
    background-color: #000;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.5rem 0.2rem;
    z-index: 10;
    img {
      width: 1.14rem;
      height: 0.7rem;
    }
    .info {
      display: flex;
      align-items: center;
      span {
        display: flex;
        align-items: center;
        padding: 0.15rem 0.17rem 0.15rem 0.2rem;
        box-sizing: border-box;
        border: 1px solid #45BBF3;
        background-color: rgba(85, 114, 241, 0.2);
        font-size: 0.24rem;
        color: #FFFF;
        i {
          display: inline-block;
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 0.1rem;
          background: url('../assets/img/address-icon.png') left top / 100% 100% no-repeat;
          &.err {
            background-image: url('../assets/img/addr-err.png');
          }
        }
      }
      .language {
        color: #FFF;
        margin: 0 0.25rem;
        position: relative;
        .language-box {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0.6rem;
          height: 0;
          overflow: hidden;
          box-sizing: border-box;
          transition: height 0.3s;
          &.show {
            height: 3.1rem;
          }
        }
        .language-list {
          width: 1.2rem;
          background-color: rgba(85,114,241,0.5);
          border: 1px solid #45BBF3;
          li {
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      .meun {
        width: 0.6rem;
        height: 0.46rem;
        cursor: pointer;
      }
    }
  }

  .meun-list {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    padding: 80px 0 0;
    li {
      width: 4.4rem;
      height: 1rem;
      color: #FFF;
      text-align: center;
      font-size: 00.28rem;
      line-height: 1rem;
      box-sizing: border-box;
      border-bottom: 1px solid #BBB;
      cursor: pointer;
      &:last-child {
        border-bottom-width: 0;
      }
    }
  }
  .drawer-close {
    position: absolute;
    right: 1rem;
    top: 0.6rem;
    width: 0.4rem;
    height: 0.4rem;
    background: url('../assets/img/close-icon.png') left top / 100% 100% no-repeat;
    font-size: 0.5rem;
    color: #FFF;
    cursor: pointer;
  }
}
</style>
