export const BSCTEST = {

  USDT: '0x55d398326f99059ff775485246999027b3197955',
  Bind: '0x6C312DD5c40AD00Fe400d686C47972BEBE739028',
  Ambassador: '0x2E3f89c74E65561CF14DDf209f263824c64d055C',
  Envoy: '0xfD25C8d438823aae3198964992B05fEa5684D130',
  AmbassadorBoard: '0xe7a043CDeA078af1c159c5Faf60bBFE0E0Bc6548',
  EnvoyBoard: '0x58D4fB1545443f04aa4deC26053720eBbe54DaC8',
  Community: '0xc01C114F2950035dfbe115564e32af745607f257',
  Core2: '0x940a4a618908e960C7C4423e6c00164C0F634200',
  Factory: '0x2FDF3Ae9771FdaC0347d6b4e97E56F81Df0aF51E',
  AmbassadorBoardOld: '0xaB0C1f1f1fd07Ea08887CF8ddba4A08cB9a2F808',
  EnvoyBoardOld: '0x7E88036E7988BeECB5602e9f194009F412827fA6',
  Help: '0x0e159aEA667D14fb68Ccd82321EbeBa16dc955C5',
  ReleasedByDay: '0x1A60c80AF9B8092bCC64afb1a297cbeCe7d8f728',
  // Fare: '0xAa54f5C3Df8d765F972341d6bCaF8B22610401bb',
  // Fare: '0xC9d7cb358630cdd979c68a26573CE50fc76a4408',
  // Fare: '0x8C4F47621df04b73cee40C1FE1088FD0ae4d19a6',
  Fare: '0x34236B96576EDE98607a3AFE4e015F09146a099F',
  Level: '0x612D68261D9aeD5A8E4c1f687c2B2dc3337FFba1',
  Miner:'0x8e2E5447F7Fd9744cEcA45d05824485D0E6f4e98',
  chainId: 56,
  jsonRpc: 'https://bsc-dataseed.binance.org',
  FOMO:{
    ZeroAddress: '0x0000000000000000000000000000000000000000',
    Bind:"0xA17Fe8E91D9451685ce1b68A12455be9a93FC581",
    USD:"0xB9362c0507269cCc9Cf23488fb0F1598CaD36DEB",
    USDT:"0xE657215B666827F41c3F2aFA6269eB3F36Db0546",
    WF:"0xc9bD0eAf8506571044F453eab5a75cB66a89402d",
    Node:"0xe0575EF336896A1ac13E404c0A342D47851DAA21",
    MinerHelper:"0x9c250E7A3aE5Fc38de2858568525eB40A40C67DE",
    Miner:"0xA17Fe8E91D9451685ce1b68A12455be9a93FC581",
    NodeBy:"0x29f738A4CFb5AB39E522D251c0CEf69eFAfc7971",
  }

}
export const BSCMain = {

  USDT: '0x55d398326f99059ff775485246999027b3197955',
  Bind: '0x6C312DD5c40AD00Fe400d686C47972BEBE739028',
  Ambassador: '0x2E3f89c74E65561CF14DDf209f263824c64d055C',
  Envoy: '0xfD25C8d438823aae3198964992B05fEa5684D130',
  AmbassadorBoard: '0xe7a043CDeA078af1c159c5Faf60bBFE0E0Bc6548',
  EnvoyBoard: '0x58D4fB1545443f04aa4deC26053720eBbe54DaC8',
  Community: '0xc01C114F2950035dfbe115564e32af745607f257',
  Core2: '0x940a4a618908e960C7C4423e6c00164C0F634200',
  Factory: '0x2FDF3Ae9771FdaC0347d6b4e97E56F81Df0aF51E',
  AmbassadorBoardOld: '0xaB0C1f1f1fd07Ea08887CF8ddba4A08cB9a2F808',
  EnvoyBoardOld: '0x7E88036E7988BeECB5602e9f194009F412827fA6',
  Help: '0x0e159aEA667D14fb68Ccd82321EbeBa16dc955C5',
  ReleasedByDay: '0x1A60c80AF9B8092bCC64afb1a297cbeCe7d8f728',
  // Fare: '0xAa54f5C3Df8d765F972341d6bCaF8B22610401bb',
  // Fare: '0xC9d7cb358630cdd979c68a26573CE50fc76a4408',
  // Fare: '0x8C4F47621df04b73cee40C1FE1088FD0ae4d19a6',
  Fare: '0x34236B96576EDE98607a3AFE4e015F09146a099F',
  Level: '0x612D68261D9aeD5A8E4c1f687c2B2dc3337FFba1',
  Miner:'0x8e2E5447F7Fd9744cEcA45d05824485D0E6f4e98',
  chainId: 56,
  jsonRpc: 'https://bsc-dataseed.binance.org',
  FOMO:{
    ZeroAddress: '0x0000000000000000000000000000000000000000',
    Bind:"0x9bC6bcD1190B4B2573B3324e6DF4436E98612fe3",
    USD:"0xD6BdD2938e97Fe2faB860b342e13B5E28216F17e",
    USDBUY:"0x9bC6bcD1190B4B2573B3324e6DF4436E98612fe3",
    USDT:"0x55d398326f99059fF775485246999027B3197955",
    WF:"0xBad74452a09AaaE300A779f7E0A61ffabD3F3f4a",
    Node:"0xbe289039a6E788C738Ce9614744391F20Ca6e35a",
    NodeNew:"0xbe289039a6E788C738Ce9614744391F20Ca6e35a",
    MinerHelper:"0xa62a4AA0ACbD79714b57F276C0d9b038792841D9",
    Miner:"0x9bC6bcD1190B4B2573B3324e6DF4436E98612fe3",
    NodeBy:"0x29f738A4CFb5AB39E522D251c0CEf69eFAfc7971",
    DEXHelpe:"0x2b20060C1cde20F35b4E95f7546B132Ab8467a5D",
    WFSell:"0x6074774fc3a271509F41e62763F4cc24f9721627",
  }
}
export const BEVMMan = {
  USDT: '0x55d398326f99059ff775485246999027b3197955',
  Bind: '0x6C312DD5c40AD00Fe400d686C47972BEBE739028',
  Ambassador: '0x2E3f89c74E65561CF14DDf209f263824c64d055C',
  Envoy: '0xfD25C8d438823aae3198964992B05fEa5684D130',
  AmbassadorBoard: '0xe7a043CDeA078af1c159c5Faf60bBFE0E0Bc6548',
  EnvoyBoard: '0x58D4fB1545443f04aa4deC26053720eBbe54DaC8',
  Community: '0xc01C114F2950035dfbe115564e32af745607f257',

  Core2: '0x940a4a618908e960C7C4423e6c00164C0F634200',
  Factory: '0x2FDF3Ae9771FdaC0347d6b4e97E56F81Df0aF51E',
  AmbassadorBoardOld: '0xaB0C1f1f1fd07Ea08887CF8ddba4A08cB9a2F808',
  EnvoyBoardOld: '0x7E88036E7988BeECB5602e9f194009F412827fA6',
  Help: '0x0e159aEA667D14fb68Ccd82321EbeBa16dc955C5',
  ReleasedByDay: '0x1A60c80AF9B8092bCC64afb1a297cbeCe7d8f728',
  // Fare: '0xAa54f5C3Df8d765F972341d6bCaF8B22610401bb',
  // Fare: '0xC9d7cb358630cdd979c68a26573CE50fc76a4408',
  // Fare: '0x8C4F47621df04b73cee40C1FE1088FD0ae4d19a6',
  Fare: '0x34236B96576EDE98607a3AFE4e015F09146a099F',
  Level: '0x612D68261D9aeD5A8E4c1f687c2B2dc3337FFba1',
  Miner:'0x8e2E5447F7Fd9744cEcA45d05824485D0E6f4e98',
  chainId: 56,
  jsonRpc: 'https://bsc-dataseed.binance.org',
  FOMO:{
    ZeroAddress: '0x0000000000000000000000000000000000000000',
    Bind:"0x32F087f5bA7352BDDD4BD8f2c3D8A8610e4A431a",
    USD:"0xFb1Ef1d85B7d677a7B1692032404F54E39065F2d",
    USDT:"0x936843e2be6e121b8111aadacb00c786ba1f976a",
    WF:"0x5280714d4327903bb3EB9F92BCfB127C007A24f7",
    Node:"0x0A27D38fb623A6FE8552523090869AAADf5BC21A",
    Miner:"0x32F087f5bA7352BDDD4BD8f2c3D8A8610e4A431a",
    NodeBy:"0x29f738A4CFb5AB39E522D251c0CEf69eFAfc7971",
  }

}

export default BSCMain
