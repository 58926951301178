import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/js/rem'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import common from './utlis/common'
import i18n from './utlis/language/index'
import locale from 'element-ui/lib/locale'
// import en from 'element-ui/lib/locale/lang/en'
import ko from 'element-ui/lib/locale/lang/ko'
import ja from 'element-ui/lib/locale/lang/ja'
import cn from 'element-ui/lib/locale/lang/zh-CN'
import en from 'element-ui/lib/locale/lang/en'
// Vue.use(ElementUI, { locale })
if (localStorage.getItem('locale') == 'EN') {
  locale.use(en)
  i18n.locale = 'EN'
}
else if (localStorage.getItem('locale') == 'CN') {
  locale.use(cn)
  i18n.locale = 'CN'
}
else if (localStorage.getItem('locale') == 'KN'){
  locale.use(ko)
  i18n.locale = 'KN'
}
else if(localStorage.getItem('locale') == 'JP'){
  locale.use(ja)
  i18n.locale = 'JP'
}
// else if(localStorage.getItem('locale') == 'IDO'){
//   Vue.use(ElementUI, { en })
//   i18n.locale = 'en'
// }
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// export default vConsole

import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs
Vue.filter('noZero', function (value) {
  return Number(value).toFixed(6).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
})
Vue.filter('numFilter', function (value) {
  return Number(value).toFixed(6).replace(/(.*\..{0})(.*)/g, '$1').replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
})
Vue.filter('numFilter2', function (value) {
  return Number(value).toFixed(6).replace(/(.*\..{2})(.*)/g, '$1').replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
})
Vue.filter('numFilter4', function (value) {
  return String(value).replace(/(.*\..{4})(.*)/g, '$1').replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
})
Vue.filter('numFilter5', function (value) {
  return String(value).replace(/(.*\..{5})(.*)/g, '$1').replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
})
Vue.filter('numFilter6', function (value) {
  return Number(value).toFixed(6).replace(/(.*\..{6})(.*)/g, '$1').replace(/(?:\.0*|(\.\d+?)0+)$/, '$1')
})
Vue.filter('addressCharacter', function (value, startLen = 6, endLen = 4) {
  // return String(value).replace(/(.{6})(.*)(.{6})/,'$1...$3')
  return value.substr(0, startLen) + '...' + value.substr(value.length - endLen, value.length)
})

Vue.config.productionTip = false
Vue.prototype.$common = common
Vue.use(ElementUI)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
