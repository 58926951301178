export default {
  fomo:{
    startnode:"initial node",
    node1:"1. Enjoy equal amount of LP head mines",
    node2:"2. 5% weighted dividend from the entire mining pool network",
    node3:"3. Priority in obtaining studio support policies",
    payCoin:"Payment currency",
    payNum:"Payment quantity",
    balance:"Balance",
    requstnode:"Apply to join the initial node",
    joinend:"joined",
    joinsuccess:"Join the node successfully",
    joinerror:"Failed to join",
  },
  news:{
    message:'announcement',
    nowtotal:'Current total',
    endtotal:'surplus',
    nowPrice:"Current Price",
    nextPrice:"Next split price",
    queue:"Queue volume",
    nextqueue:"Split queue volume",
    angin:"redelivery",
    amount:"Personal investment",
    level:"level",
    nowlevel:"Now Level",
    levelamount:"Investment",
    star:"star",
    requst:"requst",
    requstIn:"under review",
    receive:"receive",
    received:"already received",
    requstFail:"Claim failure",
    issued:"To be issued",
    time:"time",
    requstAddress:"Apply for address",
    requstID:"ApplyUserID",
    requstLEVLE:"Application level",
    needVoucher:"Preconsumption integral",
    needUSDT:"Expenditure USDT",
    tip:"Reminder for sale",
    tipMessage:"Congratulations! Your financial order has been directed for sale",
    inokw:"I hear you",
    start:"start",
    minerid1:"Ambassador miner",
    minerid2:"Ambassador Small miner",
    minerid3:"Special envoy miner",
    minerid4:"Envoy small miner",
    nclaimed:"Waiting to be claimed",
    address:"address",
    number:"number",
    miner:"Miner",
    order:"order",
    period:"period",
    type:"type",
    shares:"shares",
    split:"split",
    vip:"vip",
    allLevelamount:"allLevelamount",
    totalPerformance:"totalPerformance",
    area:"area",
    foundation:"foundation",
    pushon:"Publish in",
  },

  com: {
    empty: 'No Data',
    pre: 'Previous',
    next: 'Next',
    balance: 'Balance',
    approve: 'Authorize {0}',
    link: 'Not linked',
    reward: 'Receive',
    welcome: 'Welcome to Welfare.DAO!',
    address: 'Inviter\'s address',
    enter: 'Enter'
  },
  msg: {
    balanceInsufficient: 'The current USDT remaining available for purchase is {0}',
    purchaseSucc: 'Purchase successful',
    PurchaseFailed: 'Purchase failed',
    approveSucc: 'Authorization successful',
    approveFaile: 'Authorization failed',
    transferSucc: 'transfer successful',
    transferFailed: 'Transfer failed',
    copySucc: 'Paste to clipboard',
    invit: 'Invitation address is bound',
    bind: 'Please enter the superior address',
    invalidAddress: 'Invalid address, please check and try again',
  },
  web: {
    banner: {
      h1: 'Welfare.DAO',
      text: 'The First Mutual Aid Ecosystem of the Metaverse',
      launch: 'Launch',
      ljCG:'Link Successfully',
      ljQB:'Link Wallet',
      bdYQ:'Bind Invitation'
    },
    team: {
      title: 'Team',
      subTitle: 'Invitation link:',
      power: 'Team performance',
      subTitle2: 'My team:',
      address: 'Address',
      sub: 'Performance'
    },
    market: {
      title: 'NFT Market',
      text1: 'Digital ownership',
      text2: 'Security',
      text3: 'Freedom to trade'
    },
    pool: {
      title: 'LP Pool',
      text1: 'Participate in the entire DEFI liquid mining ecology',
      text2: 'Enjoy static high ROI'
    },
    help: {
      title: 'Love and Mutual Aid',
      text1: 'The value-added attribute has unlimited value',
      text2: 'Enjoy platform dividends'
    },
    crowd: {
      title: 'Love Crowdfunding',
      subTitle: 'Limited Issue Love NFT Card',
      name1: 'Love Ambassador',
      name2: 'Love Envoy'
    },
    swap: {
      title: 'SWAP',
      subTitle1: 'Decentralized Exchange',
      subTitle2: 'Free exchange',
      info: [{
        title: 'Wallet',
        text: 'Welfare DAO Digital Wallet'
      }, {
        title: 'GAMEFI',
        text: 'Gamefi = Game + Defi'
      }]
    },
    footer: {
      name: 'Welfare.DAO',
      welcome: 'Welcome to our online channel to keep up with the Welfare.Dao community. '
    }
  },
  _bind_:{
    _no_id_: 'ID does not exist',
    _transfer_: 'Transfer',
    _sell_: 'Sell',
    _withdraw_: 'Extract',

    _djID_: 'Please enter the point ID',
    _myID_: 'My ID',
    _jdXJ_: 'See subordinates',
    _ztID_: 'Please enter the direct push ID',
    _must_:'Required*',
    _nomust_: 'Not required',
    _bind_: 'Bind',
    _push_:'申请星级奖励',
    _bind_successful_: 'Bind successfully',
    _bind_failed_: 'Bind failed',
    _no_stock: 'Currently there is no non-fixed stock available for withdrawal',
    _withdraw_success_: 'Withdraw successfully',
    _withdraw_failed_: 'Extraction failed',
    _sell_success_: 'Sell success',
    _sell_failed_: 'Sell failed',
  },
  app: {
    invest: {
      invest:'investment',
      MyInvest:'My Investment',
      market: 'market',
      myReward:'My Reward',
      investInfo: `Holding stocks can obtain sustainable income, let's choose the right amount to invest~`,
      voucher: 'voucher',
      EstimatedStock: 'Estimated available stock',
      FixedStock: 'Fixed Stock',
      Stock:'Stock',
      NonFixedStock: 'non-fixed stock',
      Withdraw: 'Extract',
      AvailableStock: 'Stock available for sale',
      transfer: 'transfer',
      sell: 'sell',
      state0:'Audit extraction',
      state1:'Auditing...',
      state2: 'Audit failed!',
      balance:'balance',
      unitprice:'unit price',
      price: 'price',
      Buy: 'Buy',
      withdrawAll: 'Withdraw',
      ReferralRewards: 'recommended rewards',
      ClickReward: 'click reward',
      InteractionRewards: 'Interaction Rewards',
      MatchReward: 'Match Reward',
      ManagementRewards: 'Management Rewards',
      OneClickCollection: 'One-click collection',
      DirectSubordinate: 'My direct push subordinate',
      Address:'address',
      CommunityPerformance: 'Community performance',
      sellInfo: 'Withdrawing fixed stocks will split the terminal, stop earning income, are you sure to submit for review and withdraw fixed stocks? ',
    },
    confirm: 'Confirm',
    cancel:'cancel',
    amount0:'The available amount is 0! ',
    ReceiveSuccess: 'Successful receipt with one click!',
    ReceiveFail: 'One-click receipt failed!',
    PleaseEnterNumber: 'Please enter the number',
    PleaseEnterRight: 'Please enter the correct value',
    InsufficientStock: 'Insufficient stock available',
    PleaseEnterRightAddress: 'Please enter the correct address',
    PleaseLinkWallet: 'Please link the wallet first! ',
    PleaseEnterAddress: 'Please enter the transfer address',
    PleaseSelectInvest: 'Please select investment items',
    TransferSuccessful: 'Transfer successful',
    TransferFailed: 'Transfer failed',
    ApproveSuccessful: 'Authorization successful! ',
    ApproveFailed: 'Authorization failed! ',
    investmentSuccessful: 'investment success',
    WithdrawSuccessful: 'withdraw successfully',
    WithdrawFailed: 'Withdraw failed',
    investmentFailed: 'Investment failed',
    investmentFailed2:'复投失败',
    InsufficientUSDTbalance: 'USDT balance is insufficient',
    noApproveInfo:'It is detected that you are not authorized, and you are authorizing! ',
    coin: {
      title: 'Coins',
      buy: 'Buy',
      purchased: 'Purchased:'
    },
    nft: {
      title: 'Coins',
      subscription: 'Subscription',
      has: 'Has owned',
      not: 'Has owned',
      cast: 'cast',
      single: 'A single subscription of {0}USDT can get'
    },
    mine: {
      title: 'Mine',
      subTitle: 'My NFT',
      transfer: 'Transfer',
      team: 'Team',
      subTitle1: 'Invitation link:',
      subTitle2: 'Team Performance',
      subTitle3: 'My team:',
      level: 'Level'
    },
    cooperation:{
      love:'Love and mutual aid',
      income:"Yesterday's earnings",
      total:'Total revenue',
      receive:'Receive',
      invest:'Invest',
      Authorize:'Approve',
      history:'History record',
      personal:'Personal investment quota',
      all:'All investment quota',
      promote:'Marketing',
      Link:'Sponsored links',
      copy:'Copy',
      award:'Promotion reward',
      tzjl:'investment incentive',
      grade:'grade',
      performance:'Promotion performance',
      mine:'My promotion',
      address:'Address',
      yj:'Performance',
      pre: 'Previous page',
      next: 'Next page',
      investmentRecord:'Investment record',
      amount:'amount',
      quantity:'Available quantity',
      copyCG:'Copy successfully',
      No:'No income currently available',
      receiveCG:'Received successfully',
      receiveSB:'Failed to claim',
      approveCG:'Approved successfully',
      approveSB:'Approval failed',
      investCG:'investment success',
      investSB:'Investment limit reached',
      Balance:'Balance',
      withdraw:'Withdraw',
      pledge:'Pledge',
      pledgeCG:'Pledge success',
      pledgeSB:'pledge failed',
      withdrawCG:'Withdrawal succeeded',
      withdrawSB:'Failed to withdraw',
      primary:'Primary',
      intermediate:'Intermediate',
      advanced:'Advanced',
      none:'none',
      Finish:'contract has ended',
      record:'Earnings record',
      Amount:'Amount',
      date:'Date',
      sy:'income',
      rq:'select date',
      year:'year',
      moon:'moon',
      day:'day'
    }
  }
}
