export default {
  fomo:{
    startnode:"初期ノード",
    node1:"1. 同量のLPヘッド鉱山を楽しむ",
    node2:"2. マイニングプールネットワーク全体からの 5% 加重配当",
    node3:"3. スタジオサポートポリシーの取得における優先順位",
    payCoin:"支払い通貨",
    payNum:"支払数量",
    balance:"バランス",
    requstnode:"最初のノードへの参加を申請します",
    joinend:"参加しました",
    joinsuccess:"ノードに正常に参加しました",
    joinerror:"参加できませんでした",
  },
  news:{
    message:'します',
    nowtotal:'総量',
    endtotal:'ざんりょう残量',
    nowPrice:"現在単価",
    nextPrice:"次回の分割価格です",
    queue:"順番待ちの量です",
    nextqueue:"列を分けます",
    angin:"再投です",
    amount:"個人投資額です",
    level:"レベルです",
    nowlevel:"現在のレベルです",
    levelamount:"投資額です",
    star:"星です",
    requst:"申請します",
    requstIn:"審査中です",
    receive:"受け取ります",
    received:"受領済みです",
    requstFail:"受領に失敗しました",
    issued:"発給待ちです",
    time:"時間です",
    requstAddress:"申し込み先です",
    requstID:"IDを申請します",
    requstLEVLE:"等級を申請します",
    needVoucher:"事前消費ポイントです",
    needUSDT:"事前消費ポイントです",
    tip:"リマインダー販売です",
    tipMessage:"おめでとうございます。あなたの財テク註文は売りに出しました。",
    inokw:"承知しました",
    start:"はつです",
    minerid1:"大使鉱山です",
    minerid2:"大使ミニマシンです",
    minerid3:"特使鉱山です",
    minerid4:"特使鉱山機械です",
    nclaimed:"受け取り待ちです",
    address:"アドレスです",
    number:"数です",
    miner:"鉱山機械です",
    order:"註文します",
    period:"所属期数です",
    type:"タイプです",
    shares:"出資します",
    split:"スプリットです",
    vip:"vipです",
    allLevelamount:"総投資額です",
    totalPerformance:"総業績です。",
    area:"区です",
    foundation:"財団です",
    pushon:"pushonです",

  },
  com: {
    empty: 'データなし',
    pre: '前のページ',
    next: '次のページ',
    balance: 'バランス',
    approve: '{0}を承認',
    link: 'リンクされていません',
    reward: '受け取る',
    welcome: 'Welcome to Welfare.DAO！',
    address: '招待者のアドレス',
    enter: '入力'
  },
  msg: {
    balanceInsufficient: '現在購入可能なUSDTは{0}です',
    PurchaseSucc: '購入に成功しました',
    PurchaseFailed: '購入に失敗しました',
    approveSucc: '承認に成功しました',
    approveFaile: '承認に失敗しました',
    transferSucc: '転送に成功しました',
    transferFailed: '転送に失敗しました',
    copySucc: 'クリップボードに貼り付け',
    invit: '招待状のアドレスがバインドされています',
    bind: '上位の住所を入力してください',
    invalidAddress: 'アドレスが無効です。確認してもう一度お試しください',
  },
  web: {
    banner: {
      h1: 'Welfare.DAO',
      text: 'メタバースの最初の相互扶助エコシステム',
      launch: '起動',
      ljCG:'リンク成功',
      ljQB:'リンクウォレット',
      bdYQ:'バインド招待'
    },
    team: {
      title: 'チーム',
      subTitle: '招待リンク：',
      power: 'チームパフォーマンス',
      subTitle2: '私のチーム：',
      address: 'アドレス',
      sub: 'トータルパフォーマンス'
    },
    market: {
      title: 'NFTマーケット',
      text1: 'デジタル所有権',
      text2: 'セキュリティ',
      text3: '取引の自由'
    },
    pool: {
      title: 'LPプール',
      text1: 'DEFI液体マイニングエコロジー全体に参加する',
      text2: '静的な高いROIをお楽しみください'
    },
    help: {
      title: '愛と相互扶助',
      text1: '付加価値属性には無制限の値があります',
      text2: 'プラットフォーム配当をお楽しみください'
    },
    crowd: {
      title: 'クラウドファンディングが大好き',
      subTitle: '限定版ラブNFTカード',
      name1: 'ラブアンバサダー',
      name2: '愛の使節'
    },
    swap: {
      title: 'SWAP',
      subTitle1: '分散型取引所',
      subTitle2: '無料交換',
      info: [{
        title: 'ウォレット',
        text: '福祉DAOデジタルウォレット'
      }, {
        title: 'GAMEFI',
        text: 'Gamefi = Game + Defi'
      }]
    },
    footer: {
      name: 'Welfare.DAO',
      welcome: 'Welfare.Daoコミュニティについていくために、私たちのオンラインチャネルへようこそ。'
    }
  },
  _bind_:{
    _no_id_: 'ID が存在しません',
    _transfer_: '転送',
    _sell_:'売る',
    _withdraw_: '抽出',

    _djID_: 'ポイントIDを入力してください',
    _myID_: '私のID',
    _jdXJ_: '部下を見る',
    _ztID_: 'ダイレクトプッシュIDを入力してください',
    _must_:'必須*',
    _nomust_: '不要',
    _bind_: 'バインド',
    _push_:'申请星级奖励',
    _bind_successful_: 'バインドに成功しました',
    _bind_failed_: 'バインドに失敗しました',
    _no_stock: '現在、引き出し可能な非固定在庫はありません',
    _withdraw_success_: '引き出しに成功しました',
    _withdraw_failed_: '抽出に失敗しました',
    _sell_success_: '販売成功',
    _sell_failed_: '販売に失敗しました',
  },
  app: {
   invest:{
     invest: `投資`,
     MyInvest: `私の投資`,
     market: `マーケット`,
     myReward: `私の報酬`,
     investInfo: `株を保有することで持続的な収入が得られるので、適切な投資額を選びましょう~`,
     voucher: `バウチャー`,
     EstimatedStock: `利用可能な推定在庫`,
     FixedStock: `固定在庫`,
     Stock: `在庫`,
     NonFixedStock: `非固定在庫`,
     Withdraw: `抽出`,
     AvailableStock: `利用可能な在庫`,
     transfer: `転送`,
     sell: `売る`,
     state0: `監査抽出>`,
     state1: `審査中...`,
     state2: `監査に失敗しました!`,
     balance: `バランス`,
     unitprice: `単価`,
     price: `価格`,
     Buy: `購入`,
     withdrawAll: `Withdraw`,
     ReferralRewards: `紹介報酬`,
     ClickReward: `クリック報酬`,
     InteractionRewards: `インタラクション報酬`,
     MatchReward: `マッチ報酬`,
     ManagementRewards: `管理報酬`,
     OneClickCollection: `ワンクリックコレクション`,
     DirectSubordinate: `私の直属の部下`,
     Address: `アドレス`,
     CommunityPerformance: `コミュニティパフォーマンス`,
     sellInfo:`固定株を撤回すると、ターミナルが分割され、収益を得ることができなくなります。審査のために提出し、固定株を撤回してもよろしいですか? `,
    },
   confirm: `確認`,
   cancel: `キャンセル`,
   amount0: `利用可能額は0です! `,
   ReceiveSuccess: `ワンクリックで受信成功!`,
   ReceiveFail: `ワンクリック受信に失敗しました!`,
   PleaseEnterNumber: `番号を入力してください`,
   PleaseEnterRight: `正しい値を入力してください`,
   InsufficientStock: `利用可能な在庫が不足しています`,
   PleaseEnterRightAddress: `正しいアドレスを入力してください`,
   PleaseLinkWallet: `最初にウォレットをリンクしてください! `,
   PleaseEnterAddress: `転送先アドレスを入力してください`,
   PleaseSelectInvest: `投資項目を選択してください`,
   TransferSuccessful: `転送成功`,
   TransferFailed: `転送に失敗しました`,
   ApproveSuccessful: `承認が成功しました! `,
   ApproveFailed:`認証に失敗しました! `,
   investmentSuccessful: `投資は成功しました`,
   WithdrawSuccessful: `出金成功`,
   WithdrawFailed: `出金に失敗しました`,
   investmentFailed: `投資に失敗しました`,
    investmentFailed2:'复投失败',
   InsufficientUSDTbalance: `不十分な USDT 残高`,
   noApproveInfo:`承認されていないことが検出されました。承認しています! `,
    coin: {
      title: 'コイン',
      buy: '購入',
      purchased: '購入済み：'
    },
    nft: {
      title: 'コイン',
      subscription: 'サブスクリプション',
      has: '所有している',
      not: '所有している',
      cast: 'キャスト',
      signle: '{0}USDTの単一のサブスクリプションは得ることができます',
    },
    mine: {
      title: 'my',
      subTitle: '私のNFT',
      transfer: '転送',
      team: 'チーム',
      subTitle1: '招待リンク：',
      subTitle2: 'チームパフォーマンス',
      subTitle3: '私のチーム：',
      level: '学年',
    },
    cooperation:{
      love:'愛と相互扶助',
      income:'昨日の収益',
      total:'総収入',
      receive:'受け取る',
      invest:'投資',
      Authorize:'承認',
      history:'歴史記録',
      personal:'個人投資枠',
      all:'全投資枠',
      promote:'マーケティング',
      Link:'スポンサーリンク',
      copy:'コピー',
      award:'プロモーション報酬',
      tzjl:'投資賞',
      grade:'学年',
      performance:'プロモーション実績',
      mine:'私のプロモーション',
      address:'住所',
      yj:'パフォーマンス',
      pre: '前のページ',
      next: '次のページ',
      investmentRecord:'投資実績',
      amount:'額',
      quantity:'利用可能数量',
      copyCG:'正常にコピーされました',
      No:'現在収入なし',
      receiveCG:'正常に受信されました',
      receiveSB:'請求できませんでした',
      approveCG:'承認されました',
      approveSB:'承認に失敗しました',
      investCG:'投資の成功',
      investSB:'投資上限に達しました',
      Balance:'残高',
      withdraw:'撤退',
      pledge:'誓約',
      pledgeCG:'成功を誓う',
      pledgeSB:'誓約に失敗しました',
      withdrawCG:'出金成功',
      withdrawSB:'出金できませんでした',
      primary:'主要な',
      intermediate:'中級',
      advanced:'高度',
      none:'なし',
      Finish:'契約は終了しました',
      record:'収益実績',
      Amount:'額',
      date:'時間',
      sy:'所得',
      rq:'日付を選択',
      year:'年',
      moon:'月',
      day:'日'
    }
  }
}
