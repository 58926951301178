import { ethers } from "ethers"

export const initSigner = async () => {
  let address = ''
  let { web3 } = window
  let provider = web3 && web3.currentProvider ?
    new ethers.providers.Web3Provider(web3.currentProvider) :
    new ethers.providers.JsonRpcProvider(this.$common.jsonRpc)
  try {
      // await ethereum.request({ method: 'eth_requestAccounts' });
  } catch (err) {
    console.log(err)
  }
  let signer = provider.getSigner()
  try {
    address = await signer.getAddress();
  } catch (error) {console.log(error)}
  return {
    provider,
    address,
    signer
  }
}