<template>
  <div id="app">
    <!--  -->
    <router-view/>
    <!-- <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-press-escape='false'
      :close-on-click-modal='false'
      width="6rem">
      <div class="bind-content">
        <div class="bind-title">{{ $t('com.welcome') }}</div>
        <div class="bind-tips">{{ $t('com.address') }}：</div>
        <input type="text" v-model="referee" :placeholder="$t('com.address')">
        <div class="bind-btn" @click="handleBind">{{ $t('com.enter') }}</div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { initSigner } from './utlis/index'
import Bind from './contracts/Bind.json'

export default {
  data () {
    return {
      dialogVisible: true,
      address: '',
      contracts: {},
      referee: this.$route.query.referee || ''
    }
  },
  computed: {
    // referee () {
    //   return
    // }
  },
  watch: {
    // $route: {
    //   handler(newVal) {
    //     this.referee = newVal.query.referee || ''
    //   },
    //   deep: true
    // }
  },
  mounted () {
    this.init()
    window.ethereum.on('accountsChanged', () => {
      console.log('accountsChanged');
      history.go(0)
    });
    window.ethereum.on('chainChanged', () => {
      console.log('chainChanged');
      history.go(0)
    })
  },
  methods: {
    async init () {
      let { address, provider, signer } = await initSigner()
      this.address = address
      window.contracts = this.contracts

      this.contracts.Bind = new ethers.Contract(this.$common.Bind, Bind, provider).connect(signer)

      this.getData()

      this.$router.onReady(() => {
        this.referee = this.$route.query.referee || ''
      })
    },
    async getData () {
      if (!this.address) return false
      // 账号是否激活（绑定上级地址）
      // this.contracts.Bind.isActive(this.address).then(res => {
      //   this.dialogVisible = !res
      // })
    },
    // 0x0000000000000000000000000000000000000000
    async handleBind () {
      let reg = /^0x[a-fA-F0-9]{40}$/g
      if (!this.referee) return this.$message.warning(this.$t('msg.bind'))
      else if (!reg.test(this.referee)) return this.$message.warning(this.$t('msg.invit'))
      else if (this.referee !== '0x0000000000000000000000000000000000000000') {
        try {
          let res = await this.contracts.Bind.isActive(this.referee)
          if (!res) {
            return this.$message.error(this.$t('msg.invit'))
          }
        } catch (error) {
          console.info('校验地址是否已绑定,传递参数' + this.referee + '\n', error);
          return this.$message.error(this.$t('msg.invalidAddress'))
        }
      }
      this.contracts.Bind.bindRelationship(this.referee).then(async (result) => {
        await result.wait()
        this.dialogVisible = false
        this.getData()
      }).catch((err) => {
        console.info('绑定上级地址,传递参数' + this.referee + '\n', err);
        this.$message.error(this.$t('msg.invalidAddress'))
      });
    }
  }
}
</script>

<style lang="scss">

.container {
  max-width: 8rem;
  margin: 0 auto;
  box-sizing: border-box;
  // padding: 0 0.4rem;
}
</style>

<style lang="scss" scoped>
  .el-message--warning{
    top: 100px !important;
  }
  #app ::v-deep .el-dialog {
    background: url('./assets/img/bind-bg.png') left top / 100% 100% no-repeat;
    box-sizing: border-box;
    border: 1px solid #45BBF3;
    .el-dialog__header {
      padding: .2rem 0;
      text-align: center;
    }
    .el-dialog__title{
      color: #45BBF3;
      font-weight: bold;
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
    .el-dialog__headerbtn .el-dialog__close{
      color: #fff;
      font-size: .4rem;
    }
    .el-dialog__body{
      color: #fff;
    }
  }

  .bind-content {
    text-align: center;
    font-size: 0.24rem;
    .bind-title {
      font-size: 0.36rem;
      color: #45BBF3;
      margin-bottom: 0.2rem;
    }
    .bind-tips {
      text-align: left;
      color: #FFF;
      margin-bottom: 0.1rem;
    }
    input {
      width: 100%;
      // height: 0.84rem;
      padding: 0.3rem 0.2rem;
      font-size: 0.24rem;
      background-color: rgba(85,114,241,0.5);
      border: 1px solid #45BBF3;
      box-sizing: border-box;
      color: #FFF;
    }
    .bind-btn {
      width: 4.5rem;
      height: 0.84rem;
      line-height: 0.84rem;
      font-size: 0.3rem;
      margin: 0.5rem auto 0;
      background-color: rgba(178,133,237,0.8);
      color: #FFF;
      box-sizing: border-box;
      border: 1px solid #45BBF3;
      text-shadow: 2px 2px 2px #000;
      cursor: pointer;
    }
  }
</style>
