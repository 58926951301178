import VueI18n from 'vue-i18n'
import Vue from 'vue'
import CN from './cn'
import EN from './en'
import JP from './jp'
import KN from './kn'
import IDN from './in'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'CN',
  messages: { CN, EN, KN, JP, IDN }
})

export default i18n
