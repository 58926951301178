export default {
  fomo:{
    startnode:"초기 노드",
    node1:"1. 동일한 양의 LP 헤드마인을 즐겨보세요",
    node2:"2. 전체 마이닝 풀 네트워크에서 5% 가중 배당",
    node3:"3. 스튜디오 지원 정책을 우선적으로 확보합니다.",
    payCoin:"결제 통화",
    payNum:"결제 수량",
    balance:"균형",
    requstnode:"초기 노드 가입 신청",
    joinend:"합류했다",
    joinsuccess:"노드에 성공적으로 참여했습니다.",
    joinerror:"참여하지 못했습니다.",
  },
  news:{
    message:'공고',
    nowtotal:'현재 총량',
    endtotal:'잉여 수량',
    nowPrice:"현재 단가",
    nextPrice:"다음 분할 가격",
    queue:"대기열 수",
    nextqueue:"시퀀스 크기 나누기",
    angin:"복에",
    amount:"개인 투자액",
    level:"등급",
    nowlevel:"현재 단계",
    levelamount:"투자액",
    star:"별",
    requst:"신청",
    requstIn:"심사 중",
    receive:"수령",
    received:"received",
    requstFail:"찾을 수 없음",
    issued:"발급을 기다리다",
    time:"시간",
    requstAddress:"신청 주소",
    requstID:"신청 id",
    requstLEVLE:"신청 등급",
    needVoucher:"소모적분",
    needUSDT:"소모적분",
    tip:"세일즈 알람",
    tipMessage:"축하합니다!당신의 재테크 주문서는 이미 판매를 지시했습니다",
    inokw:"알겠어요",
    start:"초",
    minerid1:"앰배서더",
    minerid2:"대사 소형 광기",
    minerid3:"특사광기",
    minerid4:"특사 소형 광기",
    nclaimed:"수령을 기다리다",
    address:"주소",
    number:"수량",
    miner:"광산 기계",
    order:"주문서",
    period:"소속 기수",
    type:"유형",
    shares:"주식 할당",
    split:"분리",
    vip:"회원",
    allLevelamount:"총투자액",
    totalPerformance:"총 실적",
    area:"구",
    foundation:"기금회",
    pushon:"게시하기",

  },
  com: {
    empty: '데이터 없음',
    pre: '이전 페이지',
    next: '다음 페이지',
    balance: '균형',
    approve: '{0} 승인',
    link: '연결되지 않음',
    reward: '받다',
    welcome: 'Welfare.DAO에 오신 것을 환영합니다!',
    address: '초대자의 주소',
    enter: '들어가다'
  },
  msg: {
    balanceInsufficient: '현재 남은 구매 가능한 USDT는 {0}입니다',
    purchaseSucc: '구매 성공',
    PurchaseFailed: '구매 실패',
    approveSucc: '승인 성공',
    approveFaile: '승인 실패',
    transferSucc: '전송 성공',
    transferFailed: '전송 실패',
    copySucc: '클립보드에 붙여넣기',
    invit: '초대 주소가 바인딩됨',
    bind: '상위 주소를 입력하세요.',
    invalidAddress: '주소가 잘못되었습니다. 확인하고 다시 시도하십시오.',
  },
  web: {
    banner: {
      h1: '복지.다오',
      text: '메타버스의 응급 상호 원조 생태계',
      launch: '실행',
      ljCG:'성공적으로 연결',
      ljQB:'링크 지갑',
      bdYQ:'초대를 묶다'
    },
    team: {
      title: '팀',
      subTitle: '초대 링크:',
      power: '팀 성과',
      subTitle2: '내 팀:',
      address: '주소',
      sub: '총괄 실적'
    },
    market: {
      title: '엔에프티마켓',
      text1: '디지털 소유권',
      text2: '보안',
      text3: '거래의 자유'
    },
    pool: {
      title: 'LP 풀',
      text1: 'DEFI 액체 채굴 생태계 전체에 참여',
      text2: '정적 높은 ROI를 즐기십시오'
    },
    help: {
      title: '사랑과 상호 원조',
      text1: '부가가치 속성에는 무제한 값이 있습니다',
      text2: '플랫폼 배당을 즐기십시오'
    },
    crowd: {
      title: '러브크라우드펀딩',
      subTitle: '한정 발행 사랑 NFT 카드',
      name1: '사랑의 대사',
      name2: '러브 엔보이'
    },
    swap: {
      title: '스왑',
      subTitle1: '탈중앙화 거래소',
      subTitle2: '무료 교환',
      info: [{
        title: '지갑',
        text: '복지다오 디지털지갑'
      }, {
        title: '게임피',
        text:'Gamefi = 게임 + Defi'
      }]
    },
    footer: {
      name: '복지.다오',
      welcome: 'Welfare.Dao 커뮤니티를 따라잡기 위해 온라인 채널에 오신 것을 환영합니다. '
    }
  },
  _bind_:{
    _no_id_: 'ID がexistenceしません',
    _transfer_: '이동',
    _sell_:'매수',
    _withdraw_: '철회',

    _djID_: '포인트 ID를 입력하세요',
    _myID_: '내 아이디',
    _jdXJ_: '부하 보기',
    _ztID_: '직접 푸시 ID를 입력하세요',
    _must_:'필수*',
    _nomust_: '필수 아님',
    _bind_: '바인딩',
    _push_:'申请星级奖励',
    _bind_successful_: '바인딩 성공',
    _bind_failed_: '결합 실패',
    _no_stock: '현재 출금 가능한 비고정 재고가 없습니다.',
    _withdraw_success_: '출금 성공',
    _withdraw_failed_: '추출 실패',
    _sell_success_: '판매 성공',
    _sell_failed_: '판매 실패',
  },
  app: {
   invest:{
     invest: `투자`,
     MyInvest: `나의 투자`,
     market: `시장`,
     myReward: `나의 보상`,
     investInfo: `주식을 보유하면 지속 가능한 수입을 얻을 수 있습니다. 적절한 투자 금액을 선택합시다~`,
     voucher: `상품권`,
     EstimatedStock: `예상 재고 있음`,
     FixedStock: `고정 주식`,
     Stock: `재고`,
     NonFixedStock: `비고정 재고`,
     Withdraw: `추출`,
     AvailableStock: `사용 가능한 재고`,
     transfer: `이전`,
     sell: `매도`,
     state0: `감사 추출>`,
     state1: `검토 중...`,
     state2: `감사 실패!`,
     balance: `균형`,
     unitprice: `단가`,
     price: `가격`,
     Buy: `구입`,
     withdrawAll: `철회`,
     ReferralRewards: `추천 보상`,
     ClickReward: `클릭 보상`,
     InteractionRewards: `상호작용 보상`,
     MatchReward: `매치 보상`,
     ManagementRewards: `관리 보상`,
     OneClickCollection:'원클릭 ​​컬렉션',
     DirectSubordinate: `내 직속 부하`,
     Address: `주소`,
     CommunityPerformance: `커뮤니티 공연`,
     sellInfo:`고정 주식을 철회하면 단말기가 분할되고 수입이 중단됩니다. 검토를 위해 제출하고 고정 주식을 철회하시겠습니까? `,
    },
    confirm: `확인`,
    cancel: `취소`,
    amount0: `사용 가능한 금액은 0입니다! `,
    ReceiveSuccess: `클릭 한 번으로 영수증 성공!`,
    ReceiveFail:'원클릭 ​​수신 실패!',
    PleaseEnterNumber: `숫자를 입력하세요`,
    PleaseEnterRight: `올바른 값을 입력하세요`,
    InsufficientStock: `재고 부족`,
    PleaseEnterRightAddress: `정확한 주소를 입력하세요`,
    PleaseLinkWallet: `먼저 지갑을 연결해주세요! `,
    PleaseEnterAddress: `이체주소를 입력해주세요`,
    PleaseSelectInvest: `투자항목을 선택하세요`,
    TransferSuccessful: `전송 성공`,
    TransferFailed: `전송 실패`,
    ApproveSuccessful: `인증에 성공했습니다! `,
    ApproveFailed:`인증 실패! `,
    investmentSuccessful: `투자 성공`,
    WithdrawSuccessful: `출금 성공`,
    WithdrawFailed: `출금 실패`,
    investmentFailed: `투자 실패`,
    investmentFailed2:'复投失败',
    InsufficientUSDTbalance: `USDT 잔액 부족`,
    noApproveInfo:`인증되지 않은 것으로 감지되었으며 인증 중입니다! `,
    coin: {
      title:'동전',
      buy: '구매',
      purchased: '구매:'
    },
    nft: {
      title: '동전',
      subscription: '구독',
      has: '소유함',
      not: '소유함',
      cast: '캐스트',
      signle: '{0}USDT의 단일 구독은 다음을 얻을 수 있습니다.'
    },
    mine: {
      title: '나',
      subTitle: '나의 NFT',
      transfer: '전송',
      team: '팀',
      subTitle1:'초대 링크:',
      subTitle2: '팀 퍼포먼스',
      subTitle3: '내 팀:',
      level: '등급'
    },
    cooperation:{
      love:'사랑과 상호 원조',
      income:'어제의 수입',
      total:'총 수익',
      receive:'받다',
      invest:'투자하다',
      Authorize:'승인하다',
      history:'역사 기록',
      personal:'개인 투자 할당량',
      all:'모든 투자 할당량',
      promote:'마케팅',
      Link:'스폰서 링크',
      copy:'복사',
      award:'프로모션 보상',
      tzjl:'투자 수상',
      grade:'등급',
      performance:'프로모션 실적',
      mine:'내 승진',
      address:'주소',
      yj:'성능',
      pre: '이전 페이지',
      next: '다음 페이지',
      investmentRecord:'투자 기록',
      amount:'양',
      quantity:'사용 가능한 수량',
      copyCG:'성공적으로 복사',
      No:'현재 사용할 수 있는 소득이 없습니다.',
      receiveCG:'성공적으로 수신됨',
      receiveSB:'소유권 주장 실패',
      approveCG:'성공적으로 승인됨',
      approveSB:'승인 실패',
      investCG:'투자 성공',
      investSB:'투자 한도 도달',
      Balance:'균형',
      withdraw:'철회하다',
      pledge:'약속',
      pledgeCG:'서약 성공',
      pledgeSB:'서약 실패',
      withdrawCG:'출금 성공',
      withdrawSB:'철회 실패',
      primary:'일 순위',
      intermediate:'중간',
      advanced:'고급의',
      none:'없음',
      Finish:'계약이 종료되었습니다',
      record:'실적 기록',
      Amount:'양',
      date:'시각',
      sy:'소득',
      rq:'날짜 선택',
      year:'년도',
      moon:'달',
      day:'낮'
    }
  }
}
